html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote::before,
blockquote::after,
q::before,
q::after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

* {
  box-sizing: border-box;
}

html {
  font-family: 'Nunito Sans', sans-serif;
  font-size: 16px;
}

body {
  background: #faf7fb; /* BG color const */
  font-size: 1rem;
  font-weight: 400;
  min-height: 100%;
}

h1,
h2,
h3,
h4 {
  margin-bottom: 1rem;
}

h1 {
  font-weight: 600;
  font-size: 2.5rem;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.75rem;
}

h4 {
  font-size: 1.3rem;
}

p {
  margin-bottom: 1rem;
}

a {
  text-decoration: none;
}

.animated {
  animation-duration: 0.6s;
  animation-fill-mode: both;
}

.visible.transition {
  display: block !important;
  visibility: visible !important;
  max-height: 300px;
  overflow: scroll;
}

@keyframes shake {
  from,
  to {
    transform: translate3d(0, 0, 0);
  }

  10%,
  30%,
  50%,
  90% {
    transform: translate3d(-10px, 0, 0);
  }

  20%,
  40%,
  60%,
  80% {
    transform: translate3d(10px, 0, 0);
  }
}

.shake {
  animation-name: shake;
}

.ui.vertical.menu .dropdown.item:not(.upward) .menu {
  top: 46px;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  left: 0px;
  box-shadow: 0px 3px 2px 0px rgba(0, 0, 0, 0.1);
}

lottie-player {
  margin: 0 auto;
}
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #652d90;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --coral: #fe8497;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, 'Segoe UI',
    Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
    'Liberation Mono', 'Courier New', monospace;
}

:focus-visible {
  outline: 1px solid var(--purple);
}

html,
body {
  /* font-family: var(--font-family-sans-serif); */
  font-family: 'Nunito Sans', sans-serif;
  font-size: 1rem;
}
button,
select {
  text-transform: none;
}
svg {
  overflow: hidden;
  vertical-align: middle;
}
button,
input,
optgroup,
select,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
textarea {
  overflow: auto;
  resize: vertical;
}
#plate-wrapper {
  max-height: calc(100vh - 656px);
  min-height: 250px;
  overflow-y: scroll;
}
#plate-wrapper > * {
  font-family: var(--font-family-sans-serif);
}
#plate-wrapper ul {
  padding-inline-start: 24px;
  list-style-type: disc;
}
#plate-wrapper ol {
  padding-inline-start: 24px;
  list-style-type: decimal;
}
#plate-wrapper strong {
  font-weight: bold;
}
#plate-wrapper em {
  font-style: italic;
}
#plate-wrapper .initials-section {
  background-color: var(--coral);
  color: white;
  text-decoration: none;
  font-size: 16px;
  font-weight: 800;
  letter-spacing: 0.2em;
  opacity: 50%;
  cursor: default;
  user-select: none;
  transition: opacity 0.33s ease-in-out;
}
#plate-wrapper .initials-section.focused {
  opacity: 100%;
}
#plate-wrapper .initials-section.finished {
  background-color: transparent;
  color: black;
  opacity: 100%;
}

/*
z-index: 5 makes options menu go UNDER the modal, thereby making the modal selection unusable
*/

/* The navbar is z-index 5, so this ensures modals stay above it on mobile */
.ReactModalPortal {
  position: relative;
  z-index: 1;
}
